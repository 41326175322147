import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

const Page = styled.div`
  margin: 30px 0 130px 0;

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }

  & .title-container {
    grid-column: 1 / 13;
    margin: 0 0 20px 0;

    & h1 {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.05em;
      color: #163262;
    }
  }

  & .left-column {
    grid-column: 1 / 8;

    @media (max-width: 1350px) {
      grid-column: 1 / 9;
    }

    @media (max-width: 1100px) {
      grid-column: 1 / 10;
    }

    @media (max-width: 900px) {
      grid-column: 1 / 13;
    }
  }

  & .right-column {
    grid-column: 10 / 13;
    position: relative;

    & .active-search-image {
      width: 100%;

      position: sticky;
      top: 50px;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  & .content-grid {
    grid-column: 1 / 12;

    &.top-row {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 40px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;

      &.top-row {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }
`;

const NoResults = styled.div`
  & p {
    margin: 0;
  }
`;

const SingleSearchResult = styled.div`
  position: relative;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;

    &.landscape {
      object-fit: contain;
      object-position: bottom;
    }

    &.portrait {
      object-fit: contain;
      width: fit-content;
      margin: 0 auto;
    }
  }

  & .text-container {
    & h1,
    & p {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.05em;

      display: inline;
      color: #163262;

      transition: 250ms color ease;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    & h1 {
      & em {
        font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
        font-style: italic;
      }
    }

    & p {
      margin: 0;
    }
  }

  &:hover {
    & .text-container {
      & h1,
      & p {
        color: #4383ef;
      }
    }
  }

  @media (max-width: 787px) {
    margin: 0 0 1em 0;
  }
`;

const SearchPage = ({ location }) => {
  const [searchResults, updateSearchResults] = useState([]);
  const [activeSearchResult, setActiveSearchResult] = useState(null);

  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#ECE5DF`,
    });
  }, []);

  useEffect(() => {
    if (
      location !== null &&
      location.state !== null &&
      location.state !== undefined
    ) {
      const sortedResults = location.state.searchResults.sort((a, b) =>
        a.type.localeCompare(b.type)
      );

      updateSearchResults(sortedResults);
    } else {
      return null;
    }
  }, [location]);

  if (
    location !== null &&
    location.state !== null &&
    location.state !== undefined
  ) {
    return (
      <>
        <Page>
          <Grid>
            {location !== null &&
            location.state !== null &&
            location.state !== undefined ? (
              <>
                <Helmet
                  title={`Search Results for '${location.state.searchQuery}'`}
                  meta={[
                    {
                      name: "og:title",
                      content: `Search Results for '${location.state.searchQuery}'`,
                    },
                  ]}
                />

                <div className="content-grid">
                  <Grid>
                    <div className="title-container">
                      <h1>Search results for ‘{location.state.searchQuery}’</h1>
                    </div>

                    <div className="left-column">
                      {searchResults !== null &&
                        searchResults !== undefined &&
                        searchResults.map((result, outerIndex) => {
                          if (result.type === "artwork") {
                            return (
                              <SingleSearchResult
                                key={`single_artwork_${result.id}_${outerIndex}`}
                                className="artwork"
                                onMouseEnter={() =>
                                  setActiveSearchResult(result)
                                }
                                onMouseLeave={() => setActiveSearchResult(null)}
                              >
                                <Link to={result.url}>
                                  <div className="text-container">
                                    <p>
                                      <span>Artwork — </span>
                                      <span
                                        className="title"
                                        dangerouslySetInnerHTML={{
                                          __html: result.data.title.html,
                                        }}
                                      />
                                    </p>
                                  </div>
                                </Link>
                              </SingleSearchResult>
                            );
                          }

                          if (result.type === "exhibition") {
                            return (
                              <SingleSearchResult
                                key={`single_exhibition_${result.id}_${outerIndex}`}
                                className="exhibition"
                                onMouseEnter={() =>
                                  setActiveSearchResult(result)
                                }
                                onMouseLeave={() => setActiveSearchResult(null)}
                              >
                                <Link to={result.url}>
                                  <div className="text-container">
                                    <p>
                                      <span>Exhibition — </span>
                                      <span
                                        className="title"
                                        dangerouslySetInnerHTML={{
                                          __html: result.data.title.html,
                                        }}
                                      />
                                      {", "}
                                      <span
                                        className="details"
                                        dangerouslySetInnerHTML={{
                                          __html: result.data.details.html,
                                        }}
                                      />
                                    </p>
                                  </div>
                                </Link>
                              </SingleSearchResult>
                            );
                          }

                          if (result.type === "news") {
                            return (
                              <SingleSearchResult
                                key={`single_news_${result.id}_${outerIndex}`}
                                className="article"
                                onMouseEnter={() =>
                                  setActiveSearchResult(result)
                                }
                                onMouseLeave={() => setActiveSearchResult(null)}
                              >
                                <Link to={result.url}>
                                  <div className="text-container">
                                    <p>
                                      <span>News — </span>
                                      <span
                                        className="title"
                                        dangerouslySetInnerHTML={{
                                          __html: result.data.title.html,
                                        }}
                                      />
                                      {", "}
                                      <span
                                        className="details"
                                        dangerouslySetInnerHTML={{
                                          __html: result.data.subtitle.html,
                                        }}
                                      />
                                    </p>
                                  </div>
                                </Link>
                              </SingleSearchResult>
                            );
                          }
                        })}
                    </div>

                    <div className="right-column">
                      <div className="active-search-image">
                        {searchResults !== null &&
                          searchResults !== undefined &&
                          activeSearchResult !== null && (
                            <>
                              {activeSearchResult.type === "news" && (
                                <>
                                  {activeSearchResult.data.image.fluid !==
                                    undefined && (
                                    <img
                                      srcSet={
                                        activeSearchResult.data.image.fluid
                                          .srcSetWebp
                                      }
                                      src={
                                        activeSearchResult.data.image.fluid
                                          .srcWebp
                                      }
                                      loading={`lazy`}
                                    />
                                  )}
                                </>
                              )}

                              {(activeSearchResult.type === "artwork" ||
                                activeSearchResult.type === "exhibition") && (
                                <>
                                  {activeSearchResult.data.images.length >=
                                    1 && (
                                    <>
                                      {activeSearchResult.data.images[0].image
                                        .fluid !== null && (
                                        <img
                                          srcSet={
                                            activeSearchResult.data.images[0]
                                              .image.fluid.srcSetWebp
                                          }
                                          src={
                                            activeSearchResult.data.images[0]
                                              .image.fluid.srcWebp
                                          }
                                          alt={
                                            activeSearchResult.data.images[0]
                                              .image.alt
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                      </div>
                    </div>

                    {location.state.searchResults.length === 0 && (
                      <div className="title-container">
                        <NoResults>
                          <p>Sorry no results were found</p>
                        </NoResults>
                      </div>
                    )}
                  </Grid>
                </div>
              </>
            ) : (
              <div className="title-container">
                <NoResults>
                  <p>Sorry no results were found</p>
                </NoResults>
              </div>
            )}
          </Grid>
        </Page>
      </>
    );
  } else {
    return (
      <>
        <Helmet
          title={`Search Results`}
          meta={[
            {
              name: "og:title",
              content: `Search Results`,
            },
          ]}
        />
        <Page>
          <Grid>
            <div className="title-container">
              <NoResults>
                <p>Sorry no results were found</p>
              </NoResults>
            </div>
          </Grid>
        </Page>
      </>
    );
  }
};

export default SearchPage;
